<template>
    <div class="h-full bg-default">
        <div class="h-full flex flex-col w-64 max-w-screen z-30">
            <template v-if="isLoading">
                <div class="w-full h-full flex items-center justify-center">
                    <v-loader />
                </div>
            </template>

            <template v-else>
                <div class="flex-shrink-0 flex items-start justify-between py-default">
                    <v-button color="transparent" class="ml-auto lg:hidden" @click.prevent="$eventBus.$emit('toggleSidebar')">
                        <v-icon class="fa fa-times" />
                    </v-button>
                </div>

                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="h-0 flex-1 flex flex-col overflow-y-auto">
                    <v-section-links :menu="menu" />
                </div>

                <div class="text-center divide-y-2 divide-default">
                    <template v-if="$me.getUser()">
                        <div class="py-default">
                            <v-button size="sm" color="transparent" :to="{ name: 'me.profile' }" :active="isRouteMe">
                                {{ $me.getUser().name }}
                            </v-button>
                        </div>
                    </template>

                    <div class="py-default">
                        <template v-if="hasImpersonationToken">
                            <v-button color="primary" @click="stopImpersonation()">
                                Stop Impersonation
                            </v-button>
                        </template>

                        <div v-if="!hasImpersonationToken">
                            <v-button :to="{ name: 'auth.logout' }" color="transparent">
                                Sign Out
                            </v-button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
// import { mapGetters } from "vuex";

export default {
    data() {
        return {
            isLoading: false,
        };
    },
    computed: {
        isRouteMe() {
            return this.$route.name.includes("me.profile", 0);
        },
        isRouteMeSecurity() {
            return this.$route.name.includes("me.security", 0);
        },
        appName() {
            return process.env.VUE_APP_NAME ?? "";
        },
        menu() {
            return [
                {
                    label: "Dashboard",
                    route: { name: "panel.dashboard" },
                    icon: "fa fa-desktop",
                    description: `Welcome to ${this.appName}`,
                },
                {
                    label: "Orders",
                    route: { name: "orders" },
                    icon: "fa fa-boxes",
                    description: "List of orders that are currently in the system.",
                    permissions: ["orders.read"],
                },
                {
                    label: "Jobs",
                    route: { name: "jobs" },
                    icon: "fas fa-suitcase",
                    description: "List of jobs that are currently in the system.",
                    permissions: ["order-lines.read"],
                },
                {
                    label: "Payouts",
                    route: { name: "payouts" },
                    icon: "fas fa-file-invoice",
                    description: "List of payouts from completed jobs in the system.",
                    permissions: ["payouts.read"],
                },
                {
                    label: "Conversations",
                    route: { name: "conversations" },
                    icon: "fas fa-comments",
                    description: `Enter a live chat with ${this.appName}.`,
                    permissions: ["conversations.read"],
                },
                {
                    label: "Users",
                    route: { name: "users" },
                    icon: "fas fa-users",
                    description: "List of users that are currently in the system.",
                    permissions: ["users.read"],
                },
                {
                    label: "Roles",
                    route: { name: "roles" },
                    icon: "fas fa-user-secret",
                    description: "List of roles that are currently in the system.",
                    permissions: ["roles.read"],
                },
                {
                    label: "Reports",
                    route: { name: "reports" },
                    icon: "fas fa-file-alt",
                    description: "List of reports that are currently in the system.",
                    permissions: ["reports.read"],
                },
            ];
        },
        hasImpersonationToken() {
            return localStorage.getItem("impersonation_token");
        },
    },
    watch: {
        "$route.name": {
            handler(to, from) {
                if (from && (to !== from)) {
                    this.$eventBus.$emit("toggleSidebar");
                }
            },
            deep: true,
            immediate: true,
        },
    },
    methods: {
        toggleSidebar() {
            this.sidebarOpen = !this.sidebarOpen;
        },
        stopImpersonation() {
            localStorage.removeItem("impersonation_token");

            window.location.href = this.$router.resolve({ name: "users" }).href;
        },
    },
};
</script>
